import Page from '#/components/shared/ui/Page';
import SituationCheck from '#/components/pages/SituationCheck';

export default function SituationCheckPage() {
  return (
    <Page title="Lebenssituations-Check">
      <SituationCheck />
    </Page>
  );
}
